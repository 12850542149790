
import { defineComponent, ref, computed, reactive, watch, onMounted } from "vue";
import useUserRepositories from "@/repositories/useUserRepositories";
import { Plus, Edit, Delete } from "@element-plus/icons";
import FilterTable from '@/components/common/FilterTable.vue'
import { formatterDollars } from '@/utils/formatter'
import { formatDateTime } from '@/utils/dateTime'

export default defineComponent({
  props: {
    baseCost: {
      type: Object,
    },
  },
  components: {
    Delete,
    FilterTable,
  },
  setup(props) {
    const state = reactive({
      search: ref<string>(""),
      showModalAddSeller: ref(false),
      tableData: ref<any[]>([]),
      sellerTeams: ref<any[]>([]),
      selectedSellerTeams: ref<any[]>([]),
      addedSellerTeams: ref<any[]>([]),
      deletedSellerTeams: ref<any[]>([]),
      selectedCatalogs: ref<any[]>([]),
      columns: ref([
        {
          title: "Created",
          dataIndex: "updatedAt",
        },
        {
          title: "Name",
          dataIndex: "name",

        },
        {
          title: "Code",
          dataIndex: "code",
        },
        {
          title: "Balance",
          dataIndex: "balance",
        },
        {
          title: "Action",
          dataIndex: "action",
        },
      ]),
    });

    const { getSellerTeams } = useUserRepositories();

    onMounted(async () => {
      let resSellerTeams = await getSellerTeams({
        page: 1,
        limit: 1000,
      });
      state.sellerTeams = resSellerTeams as any[];
      if (props && props.baseCost && props.baseCost.id) {
        let sellerTeams = await getSellerTeams({
          basecost: `${props?.baseCost?.id}`,
        });
        state.selectedSellerTeams = sellerTeams as any[];
      }
    });

    let availableSellerTeams = computed(() =>
      state.sellerTeams.filter(
        (s) => !state.selectedSellerTeams.find((sl) => sl.id === s.id)
      )
    );

    const onAddSellerTeam = (c: any) => {
      let selectedSeller = { ...c };
      state.selectedSellerTeams = [...state.selectedSellerTeams, selectedSeller];
      state.addedSellerTeams = [...state.addedSellerTeams, selectedSeller];
    };

    const onDeleteSellerTeam = (seller: any) => {
      let findIndex = state.selectedSellerTeams.findIndex((s) => s.id === seller.id);
      if (findIndex !== -1) {
        state.selectedSellerTeams.splice(findIndex, 1);
      }
      state.deletedSellerTeams = [...state.deletedSellerTeams, seller];
    };

    const onSearchSelectedSellers = async () => {
      let selectedSellerTeams = [...state.selectedSellerTeams];
      selectedSellerTeams = selectedSellerTeams.filter((s: any) =>
        s.name.toLowerCase().includes(state.search.toLowerCase())
      );
      console.log("selectedSellerTeams", selectedSellerTeams);
    };

    return {
      state,
      availableSellerTeams,
      formatterDollars,
      formatDateTime,
      onAddSellerTeam,
      onDeleteSellerTeam,
      onSearchSelectedSellers,
    };
  },
});
