
import { useNotification } from '@/composables'
import useCatalogRepositories from '@/repositories/useCatalogRepositories'
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
    baseCost: {
      type: String,
      default: '',
    },
    actionType: {
      type: String,
      default: 'catalogs',
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      action: 'add',
      unit: 'usd',
      amount: 0,
      title: `Bulk update cost selected ${props.actionType}`,
    })
    const { bulkUpdate } = useCatalogRepositories()
    const { error, success } = useNotification()
    let dialogVisible = ref(false)
    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
      console.log('basecost', props.baseCost)
    }

    const submit = async () => {
      const patchData = {
        ids: props.selected,
        actionType: 'UPDATE_COST',
        data: {
          action: state.action,
          amount: parseFloat(state.amount.toString()),
          unit: state.unit,
        },
        basecost: props.baseCost,
      }
      const { data, status } = await bulkUpdate(patchData)
      if (status === 200) {
        close()
        success('Updated')
        window.location.reload()
      } else {
        error(data.message)
      }
    }

    const close = () => {
      dialogVisible.value = false
      state.amount = 0
    }

    return {
      state,
      dialogVisible,
      toggle,
      submit,
      close,
    }
  },
})
