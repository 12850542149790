
import { defineComponent, ref, computed, reactive, onMounted, watch } from 'vue'
import { ArrowDown } from '@element-plus/icons'
import { imageUrl } from '@/utils/image'
import userCatalogRepositories from '@/repositories/userCatalogRepositories'
import useBaseCostReponsitories from '@/repositories/useBaseCostReponsitories'
import { Plus, Edit, Delete } from '@element-plus/icons'
import { ElMessageBox, ElMessage } from 'element-plus'
import AddSetCostCatalog from './AddSetCostCatalog.vue'
import { useRoute, useRouter } from 'vue-router'
import { useNotification, useFetchData } from '@/composables'
import SlotError from '@/components/common/SlotError.vue'
import FilterTable from '@/components/common/FilterTable.vue'
import { formatterDollars } from '@/utils/formatter'
import Pagination from '@/components/common/Pagination.vue'
export default defineComponent({
  components: {
    ArrowDown,
    Delete,
    Edit,
    SlotError,
    AddSetCostCatalog,
    FilterTable,
    Pagination,
  },
  props: {
    baseCost: {
      type: Object,
    },
    group: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      tableData: ref<any[]>([]),
      multipleSelection: [],
      columns: ref([
        {
          title: 'Created',
          dataIndex: 'created',
        },
        {
          title: 'Name',
          dataIndex: 'name',
        },
        {
          title: 'Code',
          dataIndex: 'catalogCode',
        },
        {
          title: 'Balance',
          dataIndex: 'balance',
        },
      ]),
      showModal: false,
      action: '$',
      showModalAddSet: false,
      type: ref('add'),
      selectedCatalogs: ref<any[]>([]),
      addedCatalogs: ref<any[]>([]),
      deletedCatalogs: ref<any[]>([]),
      listBaseCost: ref<any[]>([]),
      radio1: 'Add',
      options: ref<any[]>([]),
      selectionCatalogs: ref<any[]>([]),
      baseCostCatalogs: ref<any[]>([]),
      totalAgencyCatalogs: ref<number>(0),
      catalogs: ref<any[]>([]),
      totalAddCatalogs: ref<number>(0),
    })

    const paramAddCatalogs = reactive<any>({
      page: 1,
      limit: 10,
      dropship: null,
      isTrademark: null,
      search: null,
      basecost: null,
    })

    const route = useRoute()
    const router = useRouter()
    const { getCatalogs, getCatalogsDefault } = userCatalogRepositories()
    const { error, warning, success } = useNotification()
    const { getListBaseCost, getBaseCostGroups, updateBaseCost } =
      useBaseCostReponsitories()
    const resource = `/catalogs`
    // const resource = `http://localhost:3003/api/catalogs`

    onMounted(() => {
      getData()
    })

    const baseCostId = computed(() => {
      return props.baseCost
    })

    const selectedIds = computed(() => {
      return state.multipleSelection.map((catalog: any) => catalog._id)
    })

    const getData = async () => {
      loadBaseCostCatalogs()
      if (props && props.baseCost && props.baseCost.id) {
        state.type = 'edit'
      }
      let listBaseCost = await getListBaseCost({ page: 1, limit: 10000 })
      state.listBaseCost = listBaseCost as any[]
      let listBaseCostGroup = await getBaseCostGroups({ page: 1, limit: 10000 })
      state.options = []
      for (let index = 0; index < listBaseCostGroup.length; index++) {
        const group = listBaseCostGroup[index]
        let option = {
          label: group.name,
          options: state.listBaseCost
            .filter(b => b.group && b.group.id === group.id)
            .map(c => {
              return {
                label: c.name,
                value: c.id,
              }
            }),
        } as any
        state.options = [...state.options, option]
      }
      let optionBase = {
        label: 'Base Cost Default',
        options: state.listBaseCost
          .filter(c => !c.group)
          .map(c => {
            return {
              label: c.name,
              value: c.id,
            }
          }),
      }
      state.options = [...state.options, optionBase]
    }

    const paramAgencyCatalogs = reactive<any>({
      limit: 10,
      page: 1,
      type: null,
      search: '',
      basecost: props?.baseCost?.id,
    })

    const loadBaseCostCatalogs = async () => {
      if (props?.baseCost?.id) {
        const { documents, fetchData, total } = useFetchData(
          resource,
          paramAgencyCatalogs
        )
        await fetchData()
        state.baseCostCatalogs = documents.value as any[]
        state.totalAgencyCatalogs = total.value as number
      }
    }
    watch(paramAgencyCatalogs, loadBaseCostCatalogs)

    const getImageUrl = (path: string) => {
      return imageUrl(path, true)
    }

    const handleSelectionChange = (val: any) => {
      state.multipleSelection = val
    }

    const onSelectionCatalogs = (val: any) => {
      state.selectionCatalogs = val
    }

    const showModalCatalog = () => {
      if (!props.baseCost) {
        warning('Please Save basecost before add product')
      } else {
        state.showModal = true
      }
    }

    const onSearchCatalogs = async () => {
      const baseCost = state.listBaseCost.find(
        b => b.id === paramAddCatalogs.basecost
      )
      const isDefaultAdminBaseCost = baseCost?.isDefaultAdminBaseCost
      const params = {
        ...paramAddCatalogs,
      }
      // neu tim theo base cost mac dinh thi khong can truyen vao basecost
      if (isDefaultAdminBaseCost) {
        params.basecost = null
      }
      // const resource = `http://localhost:3003/api/catalogs/not-added/${route.params.id}/base-cost`
      const resource = `catalogs/not-added/${route.params.id}/base-cost`

      const { documents, fetchData, total } = useFetchData(resource, params)
      await fetchData()
      state.catalogs = documents.value as any[]
      state.totalAddCatalogs = total.value as number
    }

    watch(paramAddCatalogs, onSearchCatalogs)

    const onAddCatalog = (el: any) => {
      let selectedCatalog = { ...el }
      if (state.type === 'edit') {
        let exists = state.baseCostCatalogs.find(
          s => s.productCode === selectedCatalog.productCode
        )
        if (exists) {
          ElMessageBox.confirm(
            'Overwrite any existing catalogs that have the same product code.',
            'Warning',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }
          )
            .then(() => {
              onSubmitCatalogs([selectedCatalog])
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          onSubmitCatalogs([selectedCatalog])
        }
      } else {
        state.selectedCatalogs = [...state.selectedCatalogs, selectedCatalog]
        state.addedCatalogs = [...state.addedCatalogs, selectedCatalog]
        state.baseCostCatalogs = [...state.baseCostCatalogs, selectedCatalog]
      }
    }

    const onDeleteCatalog = async (catalog: any) => {
      if (state.type === 'edit') {
        let params = {
          ...props.baseCost,
          catalogs: {
            add: [],
            delete: [catalog._id],
          },
          group: props.group,
          productCatalogs: undefined,
          variantPriceMapping: undefined,
        }
        let res = await updateBaseCost(params)
        if (res.status === 201 || res.status === 200) {
          loadBaseCostCatalogs()
        } else {
          error(res.data.message)
        }
      } else {
        let findIndex = state.baseCostCatalogs.findIndex(
          s => s._id === catalog._id
        )
        if (findIndex !== -1) {
          state.selectedCatalogs.splice(findIndex, 1)
          state.baseCostCatalogs.splice(findIndex, 1)
        }
        let findIndexAdded = state.addedCatalogs.findIndex(
          s => s._id === catalog._id
        )
        if (findIndexAdded !== -1) {
          state.addedCatalogs.splice(findIndex, 1)
        }
      }
    }

    const addSetCostDialog = ref<InstanceType<typeof AddSetCostCatalog>>()
    const onShowAddSet = (action: string) => {
      if (action === 'showAddSet') {
        addSetCostDialog.value?.toggle()
      }
    }

    const onSubmitCatalogs = async (catalogs: any[]) => {
      try {
        let addedCatalogs = [...catalogs]
        let addedCatalogsIds = addedCatalogs.map((s: any) => s._id)
        let params = {
          ...props.baseCost,
          catalogs: {
            add: {
              basecost: paramAddCatalogs.basecost,
              ids: addedCatalogsIds,
            },
            delete: [],
          },
          group: props.group,
          productCatalogs: undefined, // Bỏ qua thuộc tính này khi tạo đối tượng mới
          variantPriceMapping: undefined,
        } as any

        let res = await updateBaseCost(params)
        if (res.status === 200 || res.status === 201) {
          loadBaseCostCatalogs()
          success('Add success!')
        } else {
          error(res.data.message)
        }
        return res
      } catch (error: any) {
        console.log('Error', error.response.data)
      }
    }

    const onAddSelectionCatalogs = async () => {
      if (state.type === 'edit') {
        let exists = state.selectionCatalogs.filter(
          s =>
            state.baseCostCatalogs.findIndex(
              c => c.productCode === s.productCode
            ) !== -1
        )
        if (exists && exists.length > 0) {
          ElMessageBox.confirm(
            'Overwrite any existing catalogs that have the same product code.',
            'Warning',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }
          )
            .then(async () => {
              await onSubmitCatalogs(state.selectionCatalogs)
              state.showModal = false
            })
            .catch(err => {
              console.log(err)
            })
        } else {
          await onSubmitCatalogs(state.selectionCatalogs)
          state.showModal = false
        }
      } else {
        state.selectedCatalogs = [...state.selectionCatalogs]
        state.addedCatalogs = [...state.selectionCatalogs]
        state.baseCostCatalogs = [
          ...state.baseCostCatalogs,
          ...state.selectionCatalogs,
        ]
        state.showModal = false
      }
    }

    const onFilterCallback = (data: any) => {
      paramAddCatalogs.search = data?.search
    }

    const onFilterCallbackBasecost = (data: any) => {
      paramAgencyCatalogs.search = data?.search
    }

    return {
      state,
      router,
      baseCostId,
      handleSelectionChange,
      showModalCatalog,
      onDeleteCatalog,
      onAddCatalog,
      onShowAddSet,
      getImageUrl,
      onSelectionCatalogs,
      onAddSelectionCatalogs,
      onSearchCatalogs,
      addSetCostDialog,
      selectedIds,
      onFilterCallback,
      formatterDollars,
      paramAgencyCatalogs,
      onFilterCallbackBasecost,
      paramAddCatalogs,
    }
  },
})
